.generate {
  display: flex;
  background-color: black;
  position: relative;
  z-index: 1;

  &__container {
    width: 1092px;
    margin: auto;
    padding: 170px 0 210px;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @include for-small-desktop {
      padding: 139px 20px 150px;
    }

    @include for-tablet {
      padding: 50px 20px 100px;
      flex-wrap: wrap;
      gap: 40px;
      width: 100%;
    }
  }

  &__information {
    max-width: 371px;
    color: $light-color;
    margin-top: 20px;

    @include for-tablet {
      max-width: unset;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 115%;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 24px;
  }

  &__text-big {
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__img {
    width: 450px;
    margin: 25px 30px 0 100px;
    border-radius: 200px;
    @include for-laptop {
      width: 350px;
      margin-right: 0;
    }

    @include for-tablet {
      margin-top: 60px;
    }
    @include for-phone {
      width: 100%;
      margin-left: 0;
      padding-left: 100px;
      margin-top: 90px;
    }

    &-sold-out {
      @include for-tablet {
        padding-left: 0;
      }
    }
  }
}
